.form__wrapper {
  max-width: 600px;
  width: 100%;
  margin: 30px auto;

  .form__wrapper--title {
    color: $white;
    font-family: 'montserrat-ExtraBold';
    text-align: center;
    font-size: 22px;
    display: block;
    margin: 40px 0 30px 0;
  }

  .input__wrapper {
    position: relative;

    .input__label {
      left: 25px;
      font-size: 16px;
      position: absolute;
      transform-origin: top left;
      transform: translate(0, 16px) scale(1);
      transition: all .1s ease-in-out;
      cursor: text;

      &.active {
        transform: translate(0, 4px) scale(.75);
      }
    }

    .form__input {
      padding: 35px 25px 15px 25px;


        border-width: 2px;

    }
  }

  .checkbox__wrapper {
    display: flex;
    flex-direction: row;

    .custom-control-input:checked~.custom-control-label::before {
      color: #fff;
      border-color: $green;
      background-color: $green;
      box-shadow: none;
    }

    .custom-control-input:focus:not(:checked)~.custom-control-label::before {
      border-color: $green;
    }

    p {
      color: $white;
    }
  }

  .submit__button {
    background-color: $green;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-family: 'montserrat-ExtraBold';
      letter-spacing: 2px;
      margin: 0 20px 0 0;
    }
    
  }

  .error__field {
    background-color: #fff;
    padding: 0 8px;

    .error__text {
      font-weight: 700;
      font-size: 14px;
      color: $red;
    }
  }
  
}