.selected__configuration__title {
  font-family: 'montserrat-ExtraBold';
  text-align: center;
  font-size: 22px;
  margin: 40px 0 20px 0;
  color: $white;
}

.selected__item__title {
  margin-bottom: 20px;
  font-family: 'montserrat-ExtraBold';
  color: $white;
}

.selected__element {
  padding: 15px;
  margin-bottom: 30px;

  .selected__element--inner {
    display: flex
  }

  .selected__color {
    width: 100px;
    height: 100px;
    border: 1px #ccc solid;
  }

  .selected__details {
    padding: 0 20px;
  }
}