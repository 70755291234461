.variant__container {

  &.no-models {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    h2 {
      color: #fff;
      font-family: 'montserrat-ExtraBold'
    }
    
  }

  .select__configuration_btn {
    background-color: $green !important;
    margin: 20px auto;
    color: #fff;
    display: block;
    font-size: 25px;
  }
  
  .part__name {
    margin-top: 20px;
    color: #fff;
    font-family: 'montserrat-ExtraBold'
  };
  
  .variant__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  
    .variant__container {
      width: calc(100vw / 35);
      height: calc(100vw / 35);
      margin-bottom: 8px;
      margin-right: 8px; 
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #313842;
      &.active {
        background-color: $green-border
      }
  
      .variant {
        width: calc((100vw / 35) - 6px);
        height: calc((100vw / 35) - 6px);   
      }
    }
  
    
  }
  
  .model__picker {
    display: flex;
    flex-direction: row;

    .wrapper {
      &:not(:first-child) {
        margin-left: 10px;
      }
      p {
        color: $white;
      }

      .model__picker-item {
        width: 55px;
        height: 55px;
        background-color: rgb(170, 170, 170);;
        display: block;
      }
    }
    
  }
}


