.model__list-wrapper.level-1 {
  position: relative;

  img {
    max-width: 150px;
  }

  h2 {
    color: #fff;
    text-align: center;
    margin-top: 2rem;
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    width: 100%;
  }
  
  .model__list.level-1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    & > li {
      list-style-type: none;

      & > .image__wrapper {

        .image__basic {
          cursor: pointer;
          width: 100%;
        }

        .image__active {
          width: 100%;
          cursor: pointer;
          display: none;
        }
      }

      .model__list-wrapper.level-2 {
        display: none;
      }

      &.active {

        & > .image__wrapper {

          .image__basic {
            display: none;
          }

          .image__active {
            display: block;
          }
        }

        .model__list-wrapper.level-2 {
          display: block;
        }
      }
    }
  }
}

.model__list-wrapper.level-2 {
  position: absolute;
  left: 0;
  width: 100%;

  .model__list.level-2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      list-style-type: none;
      padding: 1rem;
      .image__wrapper {

        .image__basic {
          cursor: pointer;
          width: 100%;
        }

        .image__active {
          width: 100%;
          cursor: pointer;
          display: none;
        }

        &:hover {

          .image__basic {
            display: none;
          }

          .image__active {
            display: block;
          }
        }

        &.active {
          .image__basic {
            display: none;
          }
  
          .image__active {
            display: block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .model__list-wrapper.level-1 {
    height: calc(200vh - 100px);

    &.secondLevelHidden {
      height: calc(100vh - 100px);
    }

    .model__list.level-1 {
      height: calc(100vh - 100px);
      margin: 0;
  
      & > li {
        width: 50%;
        height: max-content;
        padding: 15px 5px;
  
        & > .image__wrapper {
          padding: 0;
        }
      }
    }
  }

  .model__list-wrapper.level-2 {
    position: absolute;
    top: 100vh;

    .model__list.level-2 {
      display: flex;

      & > li {
        width: 50%;
      }
    }
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .model__list-wrapper.level-1,
  .model__list-wrapper.level-2 {
    padding: 0 4rem;

    h2 {
      font-size: 30px;
    }
  }

  .model__list.level-1 {
    align-items: center;
  }
}

@media screen and (min-width: 769px) and (max-width: 1152px) {
  .model__list-wrapper.level-1 {
    height: 400px;
    width: 100%;

    img {
      max-width: 80px;
    }

    .model__list.level-1 {
      margin: 0;
      h2 {
        margin: 20px 0;
      }

      & > li {
        padding: 0 10px;
      }
    }
  }
}

@media screen and (min-width: 1153px) {
  .model__list-wrapper.level-1 {
    height: 100px;
    max-width: 1360px;
    width: 100%;
    margin: 30px auto;

    img {
      max-width: 70px;
    }

    .model__list.level-1 {
      margin: 0;
      width: 40%;
      justify-content: left;

      h2 {
        margin: 10px 0;
        order: 99;
        font-size: 16px;
        text-align: left;
        margin-left: 10px;
      }

      & > li {
        padding: 0 10px;

        &.active {
          .model__list-wrapper.level-2 {
            display: flex;
            flex-wrap: wrap;
          }
        }
      }
    }

    .model__list-wrapper.level-2 {
      width: 60%;
      left: 40%;
      top: 0;

      .model__list.level-2 {
        justify-content: left;
      }

      h2 {
        margin-left: 25px;
      }

      li {
        padding: 0 10px;
      }
    }
  }
}