.visualisation__variant__container {
  position: relative;
  width: 75%;
  margin-left: auto;

  .blank {
    width: 100%;
  };

  .visualisation__item {
    width: 100%;
  }

  .visualisation__variant--left,
  .visualisation__variant--right,
  .visualisation__variant--top,
  .visualisation__variant--door {
    box-sizing: content-box;
    position: absolute;
    top: 0;
    left: 0;
  }

  .visualisation__variant--left,
  .visualisation__variant--right,
  .visualisation__variant--top {
    z-index: 2;
  }
}

#main {
  .print-icon {
    display: block;
    border: none;
    width: 50px;
    background-color: transparent;
    position: absolute;
    bottom: 10px;
    left: -60px;

    img {
      width: 100%;
    }
  }

  &.light {
    .print-icon.white {
      display: none;
    }
  }

  &.dark {
    .print-icon.black {
      display: none;
    }
  }
}

