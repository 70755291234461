@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@font-face {
  font-family: montserrat-Regular;
  src: url(/static/media/Montserrat-Regular.92db9a07.otf); }

@font-face {
  font-family: montserrat-ExtraBold;
  src: url(/static/media/Montserrat-ExtraBold.433ac418.otf); }

@font-face {
  font-family: montserrat-Bold;
  src: url(/static/media/Montserrat-Bold.9c71d42b.otf); }

* {
  font-family: 'montserrat-Regular'; }

.break {
  background: -webkit-gradient(linear, left top, right top, from(#ABABAB), to(#9A9A9A));
  background: linear-gradient(to right, #ABABAB, #9A9A9A); }
  .break > div {
    height: 45px;
    width: 100%;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    background: -webkit-gradient(linear, left top, right top, from(#ABABAB), to(#9A9A9A));
    background: linear-gradient(to right, #ABABAB, #9A9A9A); }

.loader {
  height: 100vh;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  justify-content: center;
  align-items: center;
  background-color: #000; }

.page404 {
  height: 100vh;
  background-color: #38404b;
  overflow: hidden; }

.error__modal .modal-title {
  text-align: center;
  width: 100%;
  display: block; }

#main.dark .basic__layout.section-1 {
  background-color: #1F262F; }

#main.dark .basic__layout.section-2 {
  background-color: #2D3443; }

#main.dark .basic__layout.section-3 {
  background-color: #38404B; }

#main.dark .basic__layout.section-4 {
  background-color: #2D3443; }

#main.light .basic__layout.section-1 {
  background-color: #adadad; }
  #main.light .basic__layout.section-1 a, #main.light .basic__layout.section-1 h2, #main.light .basic__layout.section-1 h6 {
    color: #1F262F; }

#main.light .basic__layout.section-2 {
  background-color: #2D3443; }

#main.light .basic__layout.section-3 {
  background-color: #38404B; }

#main.light .basic__layout.section-4 {
  background-color: #2D3443; }

@media print {
  body {
    -webkit-print-color-adjust: exact !important; }
  #main::before {
    content: '';
    display: block;
    height: 30px;
    width: 400px;
    margin: 100px auto;
    background-image: url("https://kreatywniewdrewnie.pl/fileadmin/redingo/logo-kreatywnie-w-drewnie.svg");
    background-size: cover; }
  .basic__layout.section-1 {
    background-color: #fff !important; }
    .basic__layout.section-1 .color-picker {
      display: none; }
    .basic__layout.section-1 .visualisation {
      max-width: 80vw !important;
      margin: 0 auto;
      width: 80vw !important; }
      .basic__layout.section-1 .visualisation .visualisation__variant__container {
        width: 80vw !important; }
        .basic__layout.section-1 .visualisation .visualisation__variant__container > img {
          width: 100% !important; }
        .basic__layout.section-1 .visualisation .visualisation__variant__container div > img {
          width: 100% !important; }
  .break {
    display: none !important; }
  .model__list-wrapper.level-1 {
    display: none; }
  .basic__layout.section-2 {
    background-color: #fff !important; }
    .basic__layout.section-2 h4, .basic__layout.section-2 h5 {
      color: #000; }
  .basic__layout.section-2,
  .basic__layout.section-3,
  .basic__layout.section-4 {
    display: none; } }

.basic__layout {
  display: flex;
  justify-content: center; }
  .basic__layout .container {
    max-width: 1360px;
    width: 100%; }

.header.navbar {
  justify-content: space-between;
  padding: 20px 15px;
  z-index: 3; }
  .header.navbar .brand {
    color: #fff;
    font-family: 'montserrat-ExtraBold';
    font-size: 22px; }
    .header.navbar .brand:hover {
      color: #fff; }
  .header.navbar .navbar-toggler * {
    color: #fff; }
  .header.navbar .collapse.navbar-collapse {
    justify-content: flex-end; }
  .header.navbar .nav-item {
    padding: 0.5rem 0; }
    .header.navbar .nav-item.models {
      border-radius: 5px;
      background-color: #38404b; }
    .header.navbar .nav-item .nav-link {
      color: #fff;
      cursor: pointer;
      font-family: 'montserrat-ExtraBold';
      padding: 0 25px; }
      .header.navbar .nav-item .nav-link:hover {
        color: #fff; }

.variant__container.no-models {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }
  .variant__container.no-models h2 {
    color: #fff;
    font-family: 'montserrat-ExtraBold'; }

.variant__container .select__configuration_btn {
  background-color: #42AB69 !important;
  margin: 20px auto;
  color: #fff;
  display: block;
  font-size: 25px; }

.variant__container .part__name {
  margin-top: 20px;
  color: #fff;
  font-family: 'montserrat-ExtraBold'; }

.variant__container .variant__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .variant__container .variant__list .variant__container {
    width: calc(100vw / 35);
    height: calc(100vw / 35);
    margin-bottom: 8px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #313842; }
    .variant__container .variant__list .variant__container.active {
      background-color: #35B25A; }
    .variant__container .variant__list .variant__container .variant {
      width: calc((100vw / 35) - 6px);
      height: calc((100vw / 35) - 6px); }

.variant__container .model__picker {
  display: flex;
  flex-direction: row; }
  .variant__container .model__picker .wrapper:not(:first-child) {
    margin-left: 10px; }
  .variant__container .model__picker .wrapper p {
    color: #fff; }
  .variant__container .model__picker .wrapper .model__picker-item {
    width: 55px;
    height: 55px;
    background-color: #aaaaaa;
    display: block; }

.visualisation__variant__container {
  position: relative;
  width: 75%;
  margin-left: auto; }
  .visualisation__variant__container .blank {
    width: 100%; }
  .visualisation__variant__container .visualisation__item {
    width: 100%; }
  .visualisation__variant__container .visualisation__variant--left,
  .visualisation__variant__container .visualisation__variant--right,
  .visualisation__variant__container .visualisation__variant--top,
  .visualisation__variant__container .visualisation__variant--door {
    box-sizing: content-box;
    position: absolute;
    top: 0;
    left: 0; }
  .visualisation__variant__container .visualisation__variant--left,
  .visualisation__variant__container .visualisation__variant--right,
  .visualisation__variant__container .visualisation__variant--top {
    z-index: 2; }

#main .print-icon {
  display: block;
  border: none;
  width: 50px;
  background-color: transparent;
  position: absolute;
  bottom: 10px;
  left: -60px; }
  #main .print-icon img {
    width: 100%; }

#main.light .print-icon.white {
  display: none; }

#main.dark .print-icon.black {
  display: none; }

.selected__configuration__title {
  font-family: 'montserrat-ExtraBold';
  text-align: center;
  font-size: 22px;
  margin: 40px 0 20px 0;
  color: #fff; }

.selected__item__title {
  margin-bottom: 20px;
  font-family: 'montserrat-ExtraBold';
  color: #fff; }

.selected__element {
  padding: 15px;
  margin-bottom: 30px; }
  .selected__element .selected__element--inner {
    display: flex; }
  .selected__element .selected__color {
    width: 100px;
    height: 100px;
    border: 1px #ccc solid; }
  .selected__element .selected__details {
    padding: 0 20px; }

.form__wrapper {
  max-width: 600px;
  width: 100%;
  margin: 30px auto; }
  .form__wrapper .form__wrapper--title {
    color: #fff;
    font-family: 'montserrat-ExtraBold';
    text-align: center;
    font-size: 22px;
    display: block;
    margin: 40px 0 30px 0; }
  .form__wrapper .input__wrapper {
    position: relative; }
    .form__wrapper .input__wrapper .input__label {
      left: 25px;
      font-size: 16px;
      position: absolute;
      -webkit-transform-origin: top left;
              transform-origin: top left;
      -webkit-transform: translate(0, 16px) scale(1);
              transform: translate(0, 16px) scale(1);
      -webkit-transition: all .1s ease-in-out;
      transition: all .1s ease-in-out;
      cursor: text; }
      .form__wrapper .input__wrapper .input__label.active {
        -webkit-transform: translate(0, 4px) scale(0.75);
                transform: translate(0, 4px) scale(0.75); }
    .form__wrapper .input__wrapper .form__input {
      padding: 35px 25px 15px 25px;
      border-width: 2px; }
  .form__wrapper .checkbox__wrapper {
    display: flex;
    flex-direction: row; }
    .form__wrapper .checkbox__wrapper .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #42AB69;
      background-color: #42AB69;
      box-shadow: none; }
    .form__wrapper .checkbox__wrapper .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #42AB69; }
    .form__wrapper .checkbox__wrapper p {
      color: #fff; }
  .form__wrapper .submit__button {
    background-color: #42AB69;
    display: flex;
    justify-content: center;
    align-items: center; }
    .form__wrapper .submit__button p {
      font-family: 'montserrat-ExtraBold';
      letter-spacing: 2px;
      margin: 0 20px 0 0; }
  .form__wrapper .error__field {
    background-color: #fff;
    padding: 0 8px; }
    .form__wrapper .error__field .error__text {
      font-weight: 700;
      font-size: 14px;
      color: #dc3545; }

.footer {
  padding: 40px 0;
  display: flex;
  justify-content: space-between; }
  .footer > * {
    color: #fff; }
  .footer .left > div {
    display: flex; }
    .footer .left > div .name {
      font-family: 'montserrat-ExtraBold';
      letter-spacing: 1px;
      font-size: 22px;
      line-height: 28px;
      background-color: rgba(255, 255, 255, 0.4); }
    .footer .left > div .box {
      background-color: #42AB69;
      width: 95px; }
  .footer .left .sub {
    font-size: 7px;
    text-align: right;
    width: 100%;
    display: block; }
  .footer .right {
    font-size: 12px; }

@media screen and (max-width: 350px) {
  .header.navbar .brand.navbar-brand {
    font-size: 16px; } }

@media screen and (max-width: 768px) {
  #root {
    overflow: hidden; }
    #root #main.light .header a {
      color: #fff; }
    #root .loader {
      width: 100vw; }
    #root .section-1 {
      padding-top: 100px; }
    #root .header.navbar {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      background-color: #111 !important;
      z-index: 10;
      border-bottom: 2px #000 solid; }
      #root .header.navbar .navbar-collapse {
        z-index: 10; }
        #root .header.navbar .navbar-collapse .nav-item {
          text-align: center;
          margin: 20px 0; }
      #root .header.navbar + .container {
        padding-top: 100px; }
    #root .picker__unactive {
      display: none; }
    #root .picker__active {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 20;
      padding: 40px 20px;
      background-color: #1F262F; }
      #root .picker__active .variant__container {
        width: calc(100vw / 12) !important;
        height: calc(100vw / 12) !important; }
        #root .picker__active .variant__container .variant {
          width: calc(100vw / 12 - 6px) !important;
          height: calc(100vw / 12 - 6px) !important; }
    #root .visualisation__variant__container {
      width: 100%; }
    #root .footer {
      flex-wrap: wrap; }
      #root .footer .name {
        font-size: 20px !important; }
      #root .footer .right {
        margin: 15px 0; } }

@media screen and (min-width: 769px) and (max-width: 1300px) {
  .variant__container .variant__list .variant__container {
    width: calc(100vw / 35);
    height: calc(100vw / 35); }
    .variant__container .variant__list .variant__container .variant {
      width: calc((100vw / 35) - 6px);
      height: calc((100vw / 35) - 6px); }
  .variant__container .part__name {
    margin: 10px 0 5px 0; } }

.model__list-wrapper.level-1 {
  position: relative; }
  .model__list-wrapper.level-1 img {
    max-width: 150px; }
  .model__list-wrapper.level-1 h2 {
    color: #fff;
    text-align: center;
    margin-top: 2rem;
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    width: 100%; }
  .model__list-wrapper.level-1 .model__list.level-1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .model__list-wrapper.level-1 .model__list.level-1 > li {
      list-style-type: none; }
      .model__list-wrapper.level-1 .model__list.level-1 > li > .image__wrapper .image__basic {
        cursor: pointer;
        width: 100%; }
      .model__list-wrapper.level-1 .model__list.level-1 > li > .image__wrapper .image__active {
        width: 100%;
        cursor: pointer;
        display: none; }
      .model__list-wrapper.level-1 .model__list.level-1 > li .model__list-wrapper.level-2 {
        display: none; }
      .model__list-wrapper.level-1 .model__list.level-1 > li.active > .image__wrapper .image__basic {
        display: none; }
      .model__list-wrapper.level-1 .model__list.level-1 > li.active > .image__wrapper .image__active {
        display: block; }
      .model__list-wrapper.level-1 .model__list.level-1 > li.active .model__list-wrapper.level-2 {
        display: block; }

.model__list-wrapper.level-2 {
  position: absolute;
  left: 0;
  width: 100%; }
  .model__list-wrapper.level-2 .model__list.level-2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .model__list-wrapper.level-2 .model__list.level-2 li {
      list-style-type: none;
      padding: 1rem; }
      .model__list-wrapper.level-2 .model__list.level-2 li .image__wrapper .image__basic {
        cursor: pointer;
        width: 100%; }
      .model__list-wrapper.level-2 .model__list.level-2 li .image__wrapper .image__active {
        width: 100%;
        cursor: pointer;
        display: none; }
      .model__list-wrapper.level-2 .model__list.level-2 li .image__wrapper:hover .image__basic {
        display: none; }
      .model__list-wrapper.level-2 .model__list.level-2 li .image__wrapper:hover .image__active {
        display: block; }
      .model__list-wrapper.level-2 .model__list.level-2 li .image__wrapper.active .image__basic {
        display: none; }
      .model__list-wrapper.level-2 .model__list.level-2 li .image__wrapper.active .image__active {
        display: block; }

@media screen and (max-width: 768px) {
  .model__list-wrapper.level-1 {
    height: calc(200vh - 100px); }
    .model__list-wrapper.level-1.secondLevelHidden {
      height: calc(100vh - 100px); }
    .model__list-wrapper.level-1 .model__list.level-1 {
      height: calc(100vh - 100px);
      margin: 0; }
      .model__list-wrapper.level-1 .model__list.level-1 > li {
        width: 50%;
        height: -webkit-max-content;
        height: -moz-max-content;
        height: max-content;
        padding: 15px 5px; }
        .model__list-wrapper.level-1 .model__list.level-1 > li > .image__wrapper {
          padding: 0; }
  .model__list-wrapper.level-2 {
    position: absolute;
    top: 100vh; }
    .model__list-wrapper.level-2 .model__list.level-2 {
      display: flex; }
      .model__list-wrapper.level-2 .model__list.level-2 > li {
        width: 50%; } }

@media screen and (min-width: 480px) and (max-width: 768px) {
  .model__list-wrapper.level-1,
  .model__list-wrapper.level-2 {
    padding: 0 4rem; }
    .model__list-wrapper.level-1 h2,
    .model__list-wrapper.level-2 h2 {
      font-size: 30px; }
  .model__list.level-1 {
    align-items: center; } }

@media screen and (min-width: 769px) and (max-width: 1152px) {
  .model__list-wrapper.level-1 {
    height: 400px;
    width: 100%; }
    .model__list-wrapper.level-1 img {
      max-width: 80px; }
    .model__list-wrapper.level-1 .model__list.level-1 {
      margin: 0; }
      .model__list-wrapper.level-1 .model__list.level-1 h2 {
        margin: 20px 0; }
      .model__list-wrapper.level-1 .model__list.level-1 > li {
        padding: 0 10px; } }

@media screen and (min-width: 1153px) {
  .model__list-wrapper.level-1 {
    height: 100px;
    max-width: 1360px;
    width: 100%;
    margin: 30px auto; }
    .model__list-wrapper.level-1 img {
      max-width: 70px; }
    .model__list-wrapper.level-1 .model__list.level-1 {
      margin: 0;
      width: 40%;
      justify-content: left; }
      .model__list-wrapper.level-1 .model__list.level-1 h2 {
        margin: 10px 0;
        order: 99;
        font-size: 16px;
        text-align: left;
        margin-left: 10px; }
      .model__list-wrapper.level-1 .model__list.level-1 > li {
        padding: 0 10px; }
        .model__list-wrapper.level-1 .model__list.level-1 > li.active .model__list-wrapper.level-2 {
          display: flex;
          flex-wrap: wrap; }
    .model__list-wrapper.level-1 .model__list-wrapper.level-2 {
      width: 60%;
      left: 40%;
      top: 0; }
      .model__list-wrapper.level-1 .model__list-wrapper.level-2 .model__list.level-2 {
        justify-content: left; }
      .model__list-wrapper.level-1 .model__list-wrapper.level-2 h2 {
        margin-left: 25px; }
      .model__list-wrapper.level-1 .model__list-wrapper.level-2 li {
        padding: 0 10px; } }

.image__loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center; }

