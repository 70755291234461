@media screen and (max-width: 350px) {
  .header.navbar .brand.navbar-brand {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  #root {
    overflow: hidden;

    #main.light .header a {
      color: #fff;
    }

    .loader {
      width: 100vw;
    }

    .section-1 {
      padding-top: 100px;
    }

    .header.navbar {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      background-color: #111 !important;
      z-index: 10;
      border-bottom: 2px #000 solid;
  
      .navbar-collapse {
        z-index: 10;

        .nav-item {
          text-align: center;
          margin: 20px 0;
        }
      }
  
      & + .container {
        padding-top: 100px;
      }
    }
  
    .picker__unactive {
      display: none;    
    }
  
    .picker__active {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 20;
      padding: 40px 20px;
      background-color: #1F262F;
  
      .variant__container {
        width: calc(100vw / 12) !important;
        height: calc(100vw / 12) !important;
  
        .variant {
          width: calc(100vw / 12 - 6px) !important;
          height: calc(100vw / 12 - 6px) !important;   
        }
      }
    }
  
    .visualisation__variant__container {
      width: 100%
    }
  
    .footer {
      flex-wrap: wrap;
  
      .name {
        font-size: 20px !important;
      }
  
      .right {
        margin: 15px 0;
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1300px) {
  .variant__container .variant__list .variant__container {
    width: calc(100vw / 35);
    height: calc(100vw / 35);

    .variant {
      width: calc((100vw / 35) - 6px);
      height: calc((100vw / 35) - 6px);
    }
  }

  .variant__container .part__name {
    margin: 10px 0 5px 0;
  }
}