@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

@font-face {
  font-family: montserrat-Regular;
  src: url(../assets/fonts/Montserrat-Regular.otf)
}

@font-face {
  font-family: montserrat-ExtraBold;
  src: url(../assets/fonts/Montserrat-ExtraBold.otf)
}

@font-face {
  font-family: montserrat-Bold;
  src: url(../assets/fonts/Montserrat-Bold.otf)
}

* {
  font-family: 'montserrat-Regular';
}

.break {
  background: linear-gradient(to right, #ABABAB, #9A9A9A);

  & > div {
    height: 45px;
    width: 100%;
    transform: translateY(-2px);
    background: linear-gradient(to right, #ABABAB, #9A9A9A);
  } 
}

.loader {
  height: 100vh;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.page404 {
  height: 100vh;
  background-color: rgb(56, 64, 75);
  overflow: hidden;
}

.error__modal .modal-title {
  text-align: center;
  width: 100%;
  display: block
}

//layout
#main {

  &.dark {
    .basic__layout {
      &.section-1 {
        background-color: $strongBlue;
      }
      &.section-2 {
        background-color: $mediumBlue;
      }
      &.section-3 {
        background-color: $lightBlue;
      }
      &.section-4 {
        background-color: $mediumBlue;
      }
    }
  }

  &.light {

    .basic__layout {
      &.section-1 {
        background-color: #adadad;

        a, h2, h6 {
          color: $strongBlue;
        }
      }
      &.section-2 {
        background-color: $mediumBlue;
      }
      &.section-3 {
        background-color: $lightBlue;
      }
      &.section-4 {
        background-color: $mediumBlue;
      }
    }
  }
}

//print

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }

  #main::before {
    content: '';
    display: block;
    height: 30px;
    width: 400px;
    margin: 100px auto;
    background-image: url('https://kreatywniewdrewnie.pl/fileadmin/redingo/logo-kreatywnie-w-drewnie.svg');
    background-size: cover;
  }

  .basic__layout.section-1 {
    background-color: #fff !important;
    
    .color-picker {
      display: none;
    }

    .visualisation {
      max-width: 80vw !important;
      margin: 0 auto;
      width: 80vw !important;

      .visualisation__variant__container {
        width: 80vw !important;

        & > img {
          width: 100% !important;
        }

        div > img {
          width: 100% !important;
        }
      }
    }
  }

  .break {
    display: none !important;
  }

  .model__list-wrapper.level-1 {
    display: none;
    // li {
    //   padding: 10px;
    // }
    // height: 600px;
    
  }

  .basic__layout.section-2 {
    background-color: #fff !important;

    h4, h5 {
      color: #000
    }
  }

  .basic__layout.section-2,
  .basic__layout.section-3,
  .basic__layout.section-4 {
    display: none;
  }
}