$white: #fff;
$green-border: #35B25A;
$green: #42AB69;
$red: #dc3545;

//colors
$strongBlue: #1F262F;
$mediumBlue: #2D3443;
$lightBlue: #38404B;

$lightGray: #ccc;
$strongGray: #AAA;