.header.navbar {
  justify-content: space-between;
  padding: 20px 15px;
  z-index: 3;

  .brand {
    color: $white;
    font-family: 'montserrat-ExtraBold';
    font-size: 22px;

    &:hover {
      color: $white;
    }
  }

  .navbar-toggler * {
    color: $white;
  }

  .collapse.navbar-collapse {
    justify-content: flex-end;
  }

  .nav-item {
    padding: 0.5rem 0;

    &.models {
      border-radius: 5px;
      background-color: rgb(56, 64, 75);
    }

    .nav-link {
      color: $white;
      cursor: pointer;
      font-family: 'montserrat-ExtraBold';
      padding: 0 25px;

      &:hover {
        color: $white;
      }
    }
  }  
}