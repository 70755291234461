.footer {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;

  & > * {
    color: $white;
  }

  .left {

    & > div {
      display: flex;

      .name {
        font-family: 'montserrat-ExtraBold';
        letter-spacing: 1px;
        font-size: 22px;
        line-height: 28px;
        background-color: rgba(255, 255, 255, .4)
      }

      .box {
        background-color: $green;
        width: 95px
      }
    }

    .sub {
      font-size: 7px;
      text-align: right;
      width: 100%;
      display: block;
    }
  }

  .right {
    font-size: 12px;
  }
}